import React, {useEffect, useRef} from "react";
import styled from 'styled-components';
import lottie from 'lottie-web';
import animation from '../animations/animation.json'
import SEO from '../components/seo'

const Container = styled.main`
  font-family: -apple-system, Roboto, sans-serif, serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
  h2 {
    span {
      color: #663399;
    }
  }
`

const AnimationWrapper = styled.div`
  max-width: 100%;
  max-height: 50vh;
  margin-top: 40px;
  svg {
    max-width: 100%;
    max-height: 50vh;
  }
`

// styles
// const pageStyles = {
//   color: "#232129",
//   padding: "96px",
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   alignItems: "center",
// }
// const animationWrapper = {
//   maxWidth: "100%",
//   maxHeight: "50vh",
//   marginTop: "40px"
// }
// const headingStyles = {
//   marginTop: 0,
//   marginBottom: 64,
//   maxWidth: 320,
// }
// const headingAccentStyles = {
//   color: "#663399",
// }
// const paragraphStyles = {
//   marginBottom: 48,
// }
// const codeStyles = {
//   color: "#8A6534",
//   padding: 4,
//   backgroundColor: "#FFF4DB",
//   fontSize: "1.25rem",
//   borderRadius: 4,
// }
// const listStyles = {
//   marginBottom: 96,
//   paddingLeft: 0,
// }
// const listItemStyles = {
//   fontWeight: "300",
//   fontSize: "24px",
//   maxWidth: "560px",
// }

// const linkStyle = {
//   color: "#8954A8",
//   fontWeight: "bold",
//   fontSize: "16px",
//   verticalAlign: "5%",
// }

// const docLinkStyle = {
//   ...linkStyle,
//   listStyleType: "none",
//   marginBottom: 24,
// }

// const descriptionStyle = {
//   color: "#232129",
//   fontSize: "14px",
// }

const IndexPage = () => {
  let animationContainer = useRef();
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);
  return (
    <>
    <SEO title="Digital Solutions" description="Whether its an app, a website, e-commerce or a chat bot - bring your business into the digital age with digital solutions from Hyper Pixel." />
    <Container>
      <title>Home Page</title>
      <h1>Great things take time.</h1>
      <h2><span>Hyper Pixel</span> is almost ready.</h2>
      <AnimationWrapper ref={animationContainer} />
    </Container>
    </>
  )
}

export default IndexPage
